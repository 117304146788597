<template>
  <Head>
    <title>Инфраструктура досуга и развлечений {{ $titleEnd }}</title>
    <meta
      name="description"
      content="VERY — это целый мир ваших любимых удовольствий. Комфортный, продуманный и красивый, он будет радовать вас каждый день. 2 500 кв. м досуга в эко-квартале - проведите день, как в парке развлечений, не покидая территории квартала."
    />
    <meta
      property="og:title"
      :content="'Инфраструктура досуга и развлечений ' + $titleEnd"
    />
    <meta
      property="og:description"
      content="VERY — это целый мир ваших любимых удовольствий. Комфортный, продуманный и красивый, он будет радовать вас каждый день. 2 500 кв. м досуга в эко-квартале - проведите день, как в парке развлечений, не покидая территории квартала."
    />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/GcaaogIqfHh8nj9abjg0ItrUnGJziLHHyMvopSpY.jpg"
    />
  </Head>
  <main class="main my-0">
    <Hero
      :content="hero"
      :breadcrumbs="[
        { name: 'Home', text: 'Главная' },
        { name: 'Advantages', text: 'Преимущества' },
        { text: hero.title },
      ]"
    />
    <Basis class="nuldeizwozheemuy" :content="basis" />
    <Image :content="image" />
    <Gallery :content="gallery" />
    <Green :content="green" />
    <Others />
    <HomeChoose />
  </main>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Basis from "@/components/adv/inner/Basis.vue";
import Image from "@/components/adv/inner/Image.vue";
import Gallery from "@/components/adv/inner/Gallery.vue";
import Green from "@/components/adv/inner/Green.vue";
import Others from "@/components/adv/inner/Others.vue";
import HomeChoose from "@/components/home/Choose.vue";
import { Head } from "@vueuse/head";
export default {
  name: "Entertainment",
  components: {
    Head,
    Hero,
    Basis,
    Image,
    Gallery,
    Green,
    Others,
    HomeChoose,
  },
  data() {
    return {
      hero: {
        title: "VERY классный!",
        text: "<p>VERY&nbsp;&mdash; это целый мир ваших любимых удовольствий. Комфортный, продуманный и&nbsp;красивый, он&nbsp;будет радовать вас каждый день.</p>",
        background: require("@i/html/entertainment/entertainment-hero.jpg"),
        video: require("@i/html/entertainment/entertainment.mp4"),
        more: "Узнайте больше",
      },

      basis: {
        h2: "Инфраструктура досуга и&nbsp;развлечений в&nbsp;ЖК&nbsp;Very",
        title:
          'Все сценарии <span class="text-masked">отдыха</span> за&nbsp;день',
        subtitle:
          "22&nbsp;230&nbsp;кв.&nbsp;м&nbsp;досуга в&nbsp;<nobr>эко-квартале</nobr>",
        text: "<p>Проведите день, как в&nbsp;парке развлечений, не&nbsp;покидая территории квартала.</p>",
        list: [
          {
            title: "Активный отдых",
            text: "<p>Зарядитесь позитивом энергичных игр, полётов на&nbsp;крылатых качелях под мостом и&nbsp;детского смеха, который сливается с&nbsp;шумом фонтана.</p>",
            img: require("@i/html/entertainment/entertainment-m-1.jpg"),
          },
          {
            title: "С&nbsp;красотой и&nbsp;вкусом",
            text: "<p>Совершите променад по&nbsp;парящему мосту, понежьтесь в&nbsp;гамаке, насладитесь видами со&nbsp;смотровых площадок и&nbsp;вкусной едой.</p>",
            img: require("@i/html/entertainment/entertainment-m-2.jpg"),
          },
          {
            title: "В&nbsp;полном умиротворении",
            text: "<p>Уединитесь в&nbsp;тишине аллей собственного уникального парка, вдали от&nbsp;детского гама и&nbsp;спортивных состязаний.</p>",
            img: require("@i/html/entertainment/entertainment-m-3.jpg"),
          },
        ],
      },

      image: {
        title: "Пешеходный мост-ручей",
        text: "<p>Мост удивительной пластичной формы&nbsp;&mdash; одна из&nbsp;архитектурных доминант эко-квартала VERY. Словно паря по&nbsp;территории на&nbsp;целых 200&nbsp;метров, он&nbsp;буквально призывает совершить прогулку и&nbsp;полюбоваться двором с&nbsp;обзорных площадок.</p>",
        img: require("@i/html/entertainment/entertainment.jpg"),
      },

      gallery: [
        {
          title: "Приватные беседки",
          img: require("@i/html/entertainment/entertainment-2.jpg"),
          text: "Собирайтесь компанией, наслаждайтесь отдыхом вдвоём, уединяйтесь и&nbsp;не&nbsp;беспокойтесь о&nbsp;погоде.",
        },
        {
          title: "Фудтраки",
          img: require("@i/html/entertainment/entertainment-3.jpg"),
          text: "Любителей сочетать весёлый день в&nbsp;парке с&nbsp;перекусами всегда ждут фуд-траки с&nbsp;кухней разных стран.",
        },
        {
          title: "Уютные рестораны",
          img: require("@i/html/home/feats-3.jpg"),
          text: "Расположены на&nbsp;уровне второго этажа и&nbsp;имеют выход на&nbsp;террасы, где красота двора открывается с&nbsp;новых сторон.",
        },
        {
          title: "Фонтан",
          img: require("@i/html/entertainment/entertainment-4.jpg"),
          text: "Большой фонтан создаёт во&nbsp;дворе особенное настроение выходного дня в&nbsp;благоустроенном городском парке.",
        },
        {
          title: "Вечнозелёная ель",
          img: require("@i/html/entertainment/entertainment-5.jpg"),
          text: "Своя ёлка&nbsp;&mdash; живая, огромная, с&nbsp;ароматами хвойного леса соберёт вокруг себя на&nbsp;Новый год и&nbsp;детей, и&nbsp;родителей.",
        },
        {
          title: "Смотровая площадка с горкой-тоннелем",
          img: require("@i/html/entertainment/entertainment-6.jpg"),
          text: "Снова почувствуйте себя ребёнком и&nbsp;скатитесь с&nbsp;высоты трёх этажей вместе с&nbsp;детьми! Не&nbsp;забудьте сделать селфи на&nbsp;вершине!",
        },
      ],

      green: {
        title: "Развлекайтесь в&nbsp;своём стиле",
        img: require("@i/html/entertainment/entertainment-green.jpg"),
        list: [
          {
            title: "Зовите друзей чаще",
            text: "<p>Соберите всех на&nbsp;пикник с&nbsp;барбекю, накройте столик на&nbsp;веранде летнего кафе. Друзья будут напрашиваться в&nbsp;гости&nbsp;&mdash; погулять в&nbsp;вашем Парке культуры!</p>",
          },
          {
            title: "Вокруг&nbsp;&mdash; сплошные удовольствия!",
            text: "<p>Если не&nbsp;хватило развлечений внутри: Москвариум, Робостанция, Музей оптических иллюзий, виртуальные аттракционы&nbsp;&mdash; в&nbsp;двух шагах от&nbsp;дома.</p>",
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.main {
  background: #f8f8f8;
}

.nuldeizwozheemuy :deep(.list > .row > div:last-child > .list__item) {
  max-width: none;
}
</style>
